import { Breadcrumb, Card, Col, Divider, Row, Modal, message, Button } from 'antd';
import { HomeOutlined, UserSwitchOutlined } from '@ant-design/icons';
import AgentsTable from '@/components/agents/AgentsTable';
import RolesModal from '@/components/agents/AgentsModal';

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

// API
import APIService from '@/services/API';
import { Agent } from '@/interfaces/agent.interface';

const AgentsScreen = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
    '$sort[user.full_name]': '1',
  });

  const [modal, setModal] = useState({
    type: 'create',
    visible: false,
    data: {},
  });

  const { isLoading, data, refetch } = useQuery(['agents', params], () =>
    APIService.getAgents(params),
  );

  const handleDelete = async (id: string) => {
    const response = await APIService.deleteAgent(id);
    if (response.ok) {
      refetch();
      message.success(t('agents.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };

  const onDelete = (id: string) => {
    Modal.confirm({
      title: t('agents.deleteConfirmation'),
      onOk: () => handleDelete(id),
    });
  };

  const onEdit = (data: Agent) => {
    setModal({
      visible: true,
      type: 'edit',
      data,
    });
  };

  return (
    <div className="dashboard-screen fadeIn">
      <RolesModal
        visible={modal.visible}
        edit={modal.type === 'edit'}
        data={modal.data}
        onOk={() => {
          refetch();
          setModal({ visible: false, type: 'create', data: {} });
        }}
        onCancel={() => {
          setModal({ visible: false, type: 'create', data: {} });
        }}
      />
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '#',
                  onClick: () => nav('/dashboard'),
                  title: <HomeOutlined />,
                },
                {
                  href: '#',
                  title: (
                    <>
                      <UserSwitchOutlined />
                      <span>{t('menu.agents')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={() => nav('/dashboard/agents/new')}>
              {t('agents.create')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <AgentsTable
          current={(data?.skip || 0) / (data?.limit || 1)}
          data={data?.data}
          loading={isLoading}
          onFilter={setParams}
          total={data?.total}
          {...{ onDelete, onEdit }}
        />
      </Card>
    </div>
  );
};

export default AgentsScreen;
