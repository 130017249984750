import { Button, Table, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';
import { Agent, RawAgent } from '@/interfaces/agent.interface';
import { FilterByRegex, FilterByIcon } from '../utils/CommonFilters';
import { useNavigate } from 'react-router-dom';

export interface AgentTableProps {
  data?: RawAgent[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: Agent) => void;
  onView?: (data: Agent) => void;
  onDelete?: (id: string) => void;
}

const AgentTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
}: AgentTableProps) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const columns: ColumnsType<RawAgent> = [
    {
      title: t('users.fullName'),
      dataIndex: ['user', 'full_name'],
      key: 'user.full_name',
      sorter: true,
      defaultSortOrder: 'ascend',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('users.email'),
      dataIndex: ['user', 'email'],
      key: 'user.email',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('users.cellphone'),
      dataIndex: 'cellphone',
      key: 'cellphone',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('agents.license'),
      dataIndex: 'license',
      key: 'license',
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: RawAgent) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => nav('/dashboard/agents/detail/' + record._id)}
          >
            {t('general.view')}
          </Button>
          <Button type="link" onClick={() => nav('/dashboard/agents/edit/' + record._id)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any = {}) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value, sorter),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default AgentTable;
